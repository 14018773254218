import React from "react";
import "./Loginright.css";

function Loginright() {
  return (
    <>
      <div className="Loginright">
        <div className="Imagecontainer">
          <div className="Headerscontainer">
            <div className="Header">
              ADMINISTRER DIN VIRKSOMHED FRA HVOR SOM HELST
            </div>
            <div className="Subheader">BOOKINGHERO ™</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginright;
