import { Tr, Td, Link, Icon } from "@chakra-ui/react";
import React from "react";
import { MdPictureAsPdf } from "react-icons/md";
import "./BillingHistory.css";

function BillingHistory({ invoice, plan, amount, status }) {
  return (
    <>
      <Tr className="BillingHistory">
        <Td className="invoice">
          <Icon as={MdPictureAsPdf} h={8} w={8} marginRight={2} />
          {invoice}
        </Td>
        <Td>{plan}</Td>
        <Td>{amount}</Td>
        <Td>
          <div className={status === "Paid" ? "paid" : "unpaid"}>{status}</div>
        </Td>
        <Td>
          <Link className="Downloadlink">Download</Link>
        </Td>
      </Tr>
    </>
  );
}

export default BillingHistory;
