import React from "react";
import Loginleft from "../components/Loginleft";
import Loginright from "../components/Loginright";
import "./Login.css";
import { Helmet } from "react-helmet-async";

function Login() {
  return (
    <>
      <div className="Login">
        <Loginleft className="Loginleft" />
        <Loginright className="Loginright" />
      </div>
    </>
  );
}

export default Login;
