import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from "./App";
// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Login from "./pages/Login";
import Fergotpass from "./components/Fergotpass";
import Otp from "./components/Otp";
import Newpass from "./components/Newpass";
import Business from "./pages/Business";
import { AuthcontextProvider } from "./components/context/Authcontext";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

axios.defaults.baseURL = "https://api-v3.bookinghero.io/api/";
//axios.defaults.baseURL = "http://localhost:5000/api/";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="317838096961-s50tjit42khbsarv6kdi47u3cmhsnkdn.apps.googleusercontent.com">
        <HelmetProvider>
          <AuthcontextProvider>
            <ChakraProvider>
              <Routes>
                <Route
                  exact
                  path="/booking-system/fergotpassword"
                  element={<Fergotpass />}
                />

                <Route
                  exact
                  path="/booking-system/login/fergotpassword/otp"
                  element={
                    localStorage.getItem("forgotpasssaloon") ? (
                      <Otp />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  exact
                  path="/booking-system/login/fergotpassword/reset"
                  element={
                    localStorage.getItem("forgotpasssaloon") ? (
                      <Newpass />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route exact path="/booking-system/*" element={<Business />} />
                <Route exact path="/booking-system/login" element={<Login />} />
              </Routes>
            </ChakraProvider>
          </AuthcontextProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
